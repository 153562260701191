import {
  GET_ALL_SENSORS,
  GET_ALL_SENSORS_SUCCESS,
  GET_SENSOR_DETAILS,
  GET_SENSOR_DETAILS_SUCCESS,
  GET_ALL_SENSOR_MODELS,
  GET_ALL_SENSOR_MODELS_SUCCESS,
  CREATE_SENSOR,
  EDIT_SENSOR,
  DELETE_SENSOR
} from 'constants/ActionTypes';

export const getAllSensors = () => {
  return {
    type: GET_ALL_SENSORS
  };
};

export const getAllSensorsSuccess = sensors => {
  return {
    type: GET_ALL_SENSORS_SUCCESS,
    payload: sensors
  };
};

export const getSensorDetails = (sensorId, form) => {
  return {
    type: GET_SENSOR_DETAILS,
    payload: { sensorId, form }
  };
};

export const getSensorDetailsSuccess = sensors => {
  return {
    type: GET_SENSOR_DETAILS_SUCCESS,
    payload: sensors
  };
};

export const getAllSensorModels = () => {
  return {
    type: GET_ALL_SENSOR_MODELS
  };
};

export const getAllSensorModelsSuccess = sensors => {
  return {
    type: GET_ALL_SENSOR_MODELS_SUCCESS,
    payload: sensors
  };
};

export const createSensor = sensor => {
  return {
    type: CREATE_SENSOR,
    payload: sensor
  };
};

export const editSensor = sensor => {
  return {
    type: EDIT_SENSOR,
    payload: sensor
  };
};

export const deleteSensor = sensorId => {
  return {
    type: DELETE_SENSOR,
    payload: sensorId
  };
};
