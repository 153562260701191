import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./dashboard'))} />
      <Route path={`${match.url}auth`} component={asyncComponent(() => import('./auth'))} />
      <Route path={`${match.url}assets`} component={asyncComponent(() => import('./assets'))} />
      <Route path={`${match.url}tasks`} component={asyncComponent(() => import('./tasks'))} />
      <Route path={`${match.url}geofences`} component={asyncComponent(() => import('./geofences'))} />
      <Route path={`${match.url}alerts`} component={asyncComponent(() => import('./alerts'))} />
      <Route path={`${match.url}tenants`} component={asyncComponent(() => import("./tenants"))}/>
      <Route path={`${match.url}devices`} component={asyncComponent(() => import('./devices'))} />
      <Route path={`${match.url}reports`} component={asyncComponent(() => import('./reports'))} />
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./users'))} />
    </Switch>
  </div>
);

export default App;
