import { store } from 'NextApp'
import { personnelBase, apiBuilder, getAuthHeaders } from './index'

export const getAllTasksApi = () =>
  apiBuilder(`${personnelBase}/AdminTask/all`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const getTaskDetailsApi = (taskId) =>
  apiBuilder(`${personnelBase}/AdminTask/${taskId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const createTaskApi = (task) =>
  apiBuilder(`${personnelBase}/AdminTask`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...task })
  })

export const addComment = (taskComment) =>
  apiBuilder(`${personnelBase}/Comment`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...taskComment })
  })

export const addImage = (taskImage) =>
  apiBuilder(`${personnelBase}/TaskImage`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...taskImage })
  })

export const editTaskApi = (task) =>
  apiBuilder(`${personnelBase}/AdminTask/${task.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...task })
  })

export const deleteTaskApi = (taskId) =>
  apiBuilder(`${personnelBase}/AdminTask/${taskId}/Delete`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  })

  

export const actualStartApi = (actualStart) =>
  apiBuilder(`${personnelBase}/Task/ActualStart`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...actualStart })
  })

export const actualEndApi = (actualEnd) =>
  apiBuilder(`${personnelBase}/Task/ActualEnd`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...actualEnd })
  })

export const clockApi = (clock) =>
  apiBuilder(`${personnelBase}/Clock/Admin`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...clock })
  })
