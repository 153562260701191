import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getTripDetailsApi = assetId =>
  apiBuilder(`${coreBase}/trips/${assetId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const createTripPlanApi = plan =>
  apiBuilder(`${coreBase}/trips/plan`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(plan)
  });
