import {
  UPDATE_MARKER,
  SET_MARKERS,
  SET_MARKERS_SUCCESS,
  SET_MAP_API,
  MAP_SET_DIRECTION_RESULT,
  SET_CURRENT_ADDRESS_SUCCESS,
  SHOW_HINT,
  HIDE_HINT,
  SET_ASSET_DETAIL_SHOW_MARKER
} from 'constants/ActionTypes';
import { produce } from 'immer';

const INIT_STATE = {
  markers: [],
  marker: null,
  mapInstance: null,
  directionsService: null,
  directionsRenderer: null,
  LatLng: null,
  LatLngBounds: null,
  directionResult: null,
  geocoder: null,
  hint: false,
  assetDetailShowMarker: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MARKERS: {
      return {
        ...state,
        geocoder: action.payload.geocoder
      };
    }
    case SET_MARKERS_SUCCESS: {
      return {
        ...state,
        markers: action.payload,
        marker: action.payload.length === 1 ? action.payload[0] : null
      };
    }
    case UPDATE_MARKER: {

      let newMarkers = state.markers.slice();
      let newMarker = state.marker;
      const index = state.markers.findIndex(marker => marker.id === action.payload.id);
      if (index !== -1) {
        newMarkers.splice(index, 1, action.payload);
      } else {
        newMarkers.push(action.payload);
      }

      if (newMarker == null || newMarker.id === newMarkers[index].id) {
        newMarker = action.payload;
      }


      return {
        ...state,
        markers: newMarkers,
        marker: newMarker
      };


    }
    case SET_MAP_API: {
      return {
        ...state,
        ...action.payload
      };
    }
    case MAP_SET_DIRECTION_RESULT: {
      return {
        ...state,
        directionResult: action.payload
      };
    }
    case SET_CURRENT_ADDRESS_SUCCESS: {
      return {
        ...state,
        currentAddress: action.payload
      };
    }
    case HIDE_HINT: {
      return {
        ...state,
        hint: false
      };
    }
    case SHOW_HINT: {
      return {
        ...state,
        hint: true
      };
    }
    case SET_ASSET_DETAIL_SHOW_MARKER: {
      return {
        ...state,
        assetDetailShowMarker: action.payload
      };
    }
    default:
      return state;
  }
};
