import {
  GET_ALL_ASSETS_SUCCESS,
  FILTER_ASSETS,
  GET_ALL_MODELS_SUCCESS,
  GET_ASSET_DETAILS_SUCCESS,
  SET_IMPORTANT_DATES,
  SELECT_ASSET,
  GET_ASSET_TRIPS_SUCCESS,
  GET_TRIP_DETAILS_SUCCESS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_ALL_SUCCESS,
  GET_REPORTS_EXCEL_SUCCESS,
  SIGNOUT_USER
} from 'constants/ActionTypes';

const INIT_STATE = {
  allAssets: [],
  filteredAssets: [],
  activeFilter: 'All',
  currentAsset: {},
  selectedAsset: {},
  models: [],
  trips: [],
  currentTrip: {},
  importantDates: [],
  reports: {
    personnel: null,
    distanceTravelledInKm: null,
    allr: null
  },
  statuses: [
    {
      id: 1,
      name: 'Parked',
      value: 'Parked',
      color: 'orange',
      colorHex: '#fa8c16'
    },
    {
      id: 2,
      name: 'Out of Network',
      value: 'OutOfNetwork',
      color: 'red',
      colorHex: '#f5222d'
    },
    {
      id: 3,
      name: 'Moving',
      value: 'Moving',
      color: 'green',
      colorHex: '#52c41a'
    },
    {
      id: 4,
      name: 'All',
      value: 'All',
      color: 'blue',
      colorHex: '#1890ff'
    }
  ]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ASSETS_SUCCESS: {
      return {
        ...state,
        allAssets: action.payload,
        filteredAssets: action.payload
      };
    }
    case FILTER_ASSETS: {
      return {
        ...state,
        activeFilter: action.payload,
        filteredAssets:
          action.payload !== 'All' ? state.allAssets.filter(asset => asset.status === action.payload) : state.allAssets
      };
    }
    case GET_ALL_MODELS_SUCCESS: {
      return {
        ...state,
        models: action.payload
      };
    }
    case GET_ASSET_DETAILS_SUCCESS: {
      return {
        ...state,
        currentAsset: action.payload
      };
    }
    case SET_IMPORTANT_DATES: {
      return {
        ...state,
        importantDates: action.payload
      };
    }
    case SELECT_ASSET: {
      return {
        ...state,
        selectedAsset: action.payload
      };
    }
    case GET_ASSET_TRIPS_SUCCESS: {
      return {
        ...state,
        trips: action.payload
      };
    }
    case GET_TRIP_DETAILS_SUCCESS: {
      return {
        ...state,
        currentTrip: action.payload
      };
    }
    case GET_REPORTS_SUCCESS: {
      return {
        ...state,
        reports: {
          personnel: action.payload.personnel,
          distanceTravelledInKm: action.payload.distanceTravelledInKm,
          allr: null
        }
      };
    }
    case GET_REPORTS_ALL_SUCCESS: {
      return {
        ...state,
        reports: {
          allr: action.payload,
          personnel: null,
          distanceTravelledInKm: null

        }
      };
    }
    case GET_REPORTS_EXCEL_SUCCESS: {
      return {
        ...state,
        reports: {
          allr: null,
          personnel: null,
          distanceTravelledInKm: null

        }
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
