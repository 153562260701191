import {
  GET_ALL_TASKS,
  GET_ALL_TASKS_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  EDIT_TASK,
  DELETE_USER,
  DELETE_TASK
} from 'constants/ActionTypes';

export const getAllTasks = () => {
  return {
    type: GET_ALL_TASKS
  };
};

export const getAllTasksSuccess = tasks => {
  return {
    type: GET_ALL_TASKS_SUCCESS,
    payload: tasks
  };
};

export const getTaskDetails = (taskId, form) => {
  return {
    type: GET_TASK_DETAILS,
    payload: { taskId, form }
  };
};

export const getTaskDetailsSuccess = task => {
  return {
    type: GET_TASK_DETAILS_SUCCESS,
    payload: task
  };
};

export const createTask = task => {
  return {
    type: CREATE_TASK,
    payload: task
  };
};

export const createTaskSuccess = info => {
  return {
    type: CREATE_TASK_SUCCESS,
    payload: info
  };
};

export const editTask = task => {
  return {
    type: EDIT_TASK,
    payload: task
  };
};

export const deleteUser = userId => {
  return {
    type: DELETE_USER,
    payload: userId
  };
};

export const deleteTask = task => {
  return {
    type: DELETE_TASK,
    payload: task
  };
};
