import {
    GET_DASHBOARD_USER_STATUS,
    GET_DASHBOARD_USER_STATUS_SUCCESS,
    GET_DASHBOARD_ONLINE_USER,
    GET_DASHBOARD_ONLINE_USER_SUCCESS,
    GET_DASHBOARD_TASKS,
    GET_DASHBOARD_TASKS_SUCCESS
} from 'constants/ActionTypes';

export const getDashboardUserStatus = () => {
    return {
        type: GET_DASHBOARD_USER_STATUS
    };
};

export const getDashboardUserStatusSuccess = status => {
    return {
        type: GET_DASHBOARD_USER_STATUS_SUCCESS,
        payload: status
    };
};

export const getDashboardOnlineUser = () => {
    return {
        type: GET_DASHBOARD_ONLINE_USER
    };
};

export const getDashboardOnlineUserSuccess = users => {
    return {
        type: GET_DASHBOARD_ONLINE_USER_SUCCESS,
        payload: users
    };
};


export const getDashboardTasks = () => {
    return {
        type: GET_DASHBOARD_TASKS
    };
};

export const getDashboardTasksSuccess = tasks => {
    return {
        type: GET_DASHBOARD_TASKS_SUCCESS,
        payload: tasks
    };
};


