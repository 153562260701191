import {
  GET_ALL_TASKS_SUCCESS,
  GET_TASK_DETAILS_SUCCESS,
  CREATE_TASK_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  allTasks: [],
  currentTask: {},
  createdTaskInfo: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TASKS_SUCCESS: {
      return {
        ...state,
        allTasks: action.payload
      };
    }
    case GET_TASK_DETAILS_SUCCESS: {
      return {
        ...state,
        currentTask: action.payload
      };
    }
    case CREATE_TASK_SUCCESS: {
      return {
        ...state,
        createdTaskInfo: action.payload
      };
    }
    default:
      return state;
  }
};
