import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNIN_USER_REFRESH,
  SIGNIN_USER_REFRESH_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  AUTH_OPEN_DIALOG,
  AUTH_CLOSE_DIALOG,
  AUTH_CANCEL_LOADING,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  REQUEST_FORGOT_PASSWORD
} from 'constants/ActionTypes';

export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};

export const userSignInRefresh = refresh => {
  return {
    type: SIGNIN_USER_REFRESH,
    payload: refresh
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};

export const getCurrentUserSuccess = user => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: user
  };
};

export const userSignInRefreshSuccess = authUser => {
  return {
    type: SIGNIN_USER_REFRESH_SUCCESS,
    payload: authUser
  };
};

export const getCurrentUser = () => {
  return {
    type: GET_CURRENT_USER
  };
};

export const authOpenDialog = (dialogType, email, password) => {
  return {
    type: AUTH_OPEN_DIALOG,
    payload: { dialogType, email, password }
  };
};

export const authCloseDialog = () => {
  return {
    type: AUTH_CLOSE_DIALOG
  };
};

export const authCancelLoading = () => {
  return {
    type: AUTH_CANCEL_LOADING
  };
};

export const changePassword = (newPassword, oldPassword) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { newPassword, oldPassword }
  };
};

export const changePasswordSuccess = newPassword => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: newPassword
  };
};

export const requestForgotPassword = loginEmail => {
  return {
    type: REQUEST_FORGOT_PASSWORD,
    payload: loginEmail
  };
};

export const forgotPassword = (newPassword, resetToken) => {
  return {
    type: FORGOT_PASSWORD,
    payload: { newPassword, resetToken }
  };
};

export const forgotPasswordSuccess = newPassword => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: newPassword
  };
};
