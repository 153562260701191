import { personnelBase, coreBase,apiBuilder, getAuthHeaders } from './index';

export const getAllAssetsApi = () =>
  apiBuilder(`${coreBase}/assets`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getAssetDetailsApi = assetId =>
  apiBuilder(`${coreBase}/assets/${assetId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getAssetTripsApi = assetId =>
  apiBuilder(`${coreBase}/assets/${assetId}/report/trip/all`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const createAssetApi = asset =>
  apiBuilder(`${coreBase}/assets`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(asset)
  });

export const editAssetApi = asset =>
  apiBuilder(`${coreBase}/assets/${asset.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(asset)
  });

export const deleteAssetApi = assetId =>
  apiBuilder(`${coreBase}/assets/${assetId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });

export const getAllModelsApi = () =>
  apiBuilder(`${coreBase}/assets/models`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getReportMovements = payload => {
  const { userId, from, to } = payload;
  const query = from && to ? `?from=${from}&to=${to}` : '';
  return apiBuilder(`${coreBase}/assets/${userId}/report/movement/all${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
};

export const getReportLocations = payload => {
  const { assetId, from, to } = payload;
  const query = from && to ? `?from=${from}&to=${to}` : '';
  return apiBuilder(`${coreBase}/assets/${assetId}/report/timeseries/location${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
};

export const getReportWeather = payload => {
  const { assetId, from, to } = payload;
  const query = from && to ? `?from=${from}&to=${to}` : '';
  return apiBuilder(`${coreBase}/assets/${assetId}/report/timeseries/weather${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
};

export const getReportPersonnelApi = payload => {
  const { userId, from, to } = payload;
  const query = from && to ? `?from=${from}&to=${to}` : '';
  return apiBuilder(`${personnelBase}/AdminUserReport/report/${userId}${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
};

export const getReportPersonnelAllApi = payload => {
  const {  from, to } = payload;
  const query = from && to ? `?from=${from}&to=${to}` : '';
  return apiBuilder(`${personnelBase}/AdminUserReport/reportAll/${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
};


export const getReportPersonnelExcelApi = payload => {
  const {  from, to } = payload;
  const query = from && to ? `?from=${from}&to=${to}` : '';
  return apiBuilder(`${personnelBase}/AdminUserReport/reportAllExcel/${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
};