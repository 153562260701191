import { store } from 'NextApp';
import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getAllSensorsApi = () =>
  apiBuilder(`${coreBase}/sensors`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getSensorDetailsApi = sensorId =>
  apiBuilder(`${coreBase}/sensors/${sensorId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getAllSensorModelsApi = () =>
  apiBuilder(`${coreBase}/sensors/models`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const createSensorApi = sensor =>
  apiBuilder(`${coreBase}/sensors`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(sensor)
  });

export const editSensorApi = sensor =>
  apiBuilder(`${coreBase}/sensors/${sensor.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(sensor)
  });

export const deleteSensorApi = sensorId =>
  apiBuilder(`${coreBase}/sensors/${sensorId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
