import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_P,
  GET_ALL_USERS_P_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_PP_DETAILS,
  GET_USER_PP_DETAILS_SUCCESS,
  GET_ALL_USER_ROLES,
  GET_ALL_USER_ROLES_SUCCESS,
  GET_ALL_USER_TENANTS,
  GET_ALL_USER_TENANTS_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  EDIT_USER,
  DELETE_USER
} from 'constants/ActionTypes';

export const getAllUsers = () => {
  return {
    type: GET_ALL_USERS
  };
};

export const getAllUsersSuccess = users => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: users
  };
};



export const getAllUsersP = () => {
  return {
    type: GET_ALL_USERS_P
  };
};

export const getAllUsersPSuccess = users => {
  return {
    type: GET_ALL_USERS_P_SUCCESS,
    payload: users
  };
};

export const getUserPPDetails = (userId, form) => {
  return {
    type: GET_USER_PP_DETAILS,
    payload: { userId, form }
  };
};

export const getUserPPDetailsSuccess = user => {
  return {
    type: GET_USER_PP_DETAILS_SUCCESS,
    payload: user
  };
};

export const getUserDetails = (userId, form) => {
  return {
    type: GET_USER_DETAILS,
    payload: { userId, form }
  };
};

export const getUserDetailsSuccess = user => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: user
  };
};

export const getAllUserRoles = () => {
  return {
    type: GET_ALL_USER_ROLES
  };
};

export const getAllUserRolesSuccess = roles => {
  return {
    type: GET_ALL_USER_ROLES_SUCCESS,
    payload: roles
  };
};

export const getAllUserTenants = () => {
  return {
    type: GET_ALL_USER_TENANTS
  };
};

export const getAllUserTenantsSuccess = tenants => {
  return {
    type: GET_ALL_USER_TENANTS_SUCCESS,
    payload: tenants
  };
};

export const createUser = user => {
  return {
    type: CREATE_USER,
    payload: user
  };
};

export const createUserSuccess = info => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: info
  };
};

export const editUser = user => {
  return {
    type: EDIT_USER,
    payload: user
  };
};

export const deleteUser = userId => {
  return {
    type: DELETE_USER,
    payload: userId
  };
};
