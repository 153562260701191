import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_SENSORS,
  GET_SENSOR_DETAILS,
  GET_ALL_SENSOR_MODELS,
  CREATE_SENSOR,
  EDIT_SENSOR,
  DELETE_SENSOR
} from 'constants/ActionTypes';
import {
  getAllSensors,
  getAllSensorsSuccess,
  getSensorDetailsSuccess,
  getAllSensorModelsSuccess
} from '../actions/Sensors';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
  getAllSensorsApi,
  getSensorDetailsApi,
  getAllSensorModelsApi,
  createSensorApi,
  editSensorApi,
  deleteSensorApi
} from '../../api';
import { history } from 'appRedux/store';

function* getAllSensorsHandler() {
  try {
    const res = yield call(getAllSensorsApi);
    yield put(getAllSensorsSuccess(res.sensors));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getSensorDetailsHandler({ payload }) {
  const { sensorId, form } = payload;
  try {
    const res = yield call(getSensorDetailsApi, sensorId);
    if (form) form.setFieldsValue({ ...res });
    yield put(getSensorDetailsSuccess(res.sensors));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAllSensorModelsHandler() {
  try {
    const res = yield call(getAllSensorModelsApi);
    yield put(getAllSensorModelsSuccess(res.models));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* createSensorHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createSensorApi, payload);
    yield put(hideLoader());
    history.push('/devices');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* editSensorHandler({ payload }) {
  yield put(showLoader());
  try {
    yield call(editSensorApi, payload);
    yield put(hideLoader());
    history.push('/devices');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* deleteSensorHandler({ payload }) {
  try {
    const res = yield call(deleteSensorApi, payload);
    yield put(getAllSensors(res));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

export function* getAllSensorsSaga() {
  yield takeEvery(GET_ALL_SENSORS, getAllSensorsHandler);
}

export function* getSensorDetailsSaga() {
  yield takeEvery(GET_SENSOR_DETAILS, getSensorDetailsHandler);
}

export function* getAllSensorModelsSaga() {
  yield takeEvery(GET_ALL_SENSOR_MODELS, getAllSensorModelsHandler);
}

export function* createSensorSaga() {
  yield takeEvery(CREATE_SENSOR, createSensorHandler);
}

export function* editSensorSaga() {
  yield takeEvery(EDIT_SENSOR, editSensorHandler);
}

export function* deleteSensorSaga() {
  yield takeEvery(DELETE_SENSOR, deleteSensorHandler);
}

export default function* rootSaga() {
  yield all([
    fork(getAllSensorsSaga),
    fork(getSensorDetailsSaga),
    fork(getAllSensorModelsSaga),
    fork(createSensorSaga),
    fork(editSensorSaga),
    fork(deleteSensorSaga)
  ]);
}
