import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { GET_ALL_ALERTS, GET_ALERT_SETTINGS, EDIT_ALERT_SETTINGS } from 'constants/ActionTypes';
import { getAllAlertsSuccess, getAlertSettingsSuccess } from '../actions/Alerts';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import { getAllAlertsApi, getAlertGeofenceSettings, editAlertGeofenceSettings } from '../../api';
import { history } from 'appRedux/store';

function* getAllAlertsHandler() {
  try {
    const res = yield call(getAllAlertsApi);
    yield put(getAllAlertsSuccess(res.alerts));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getAlertSettingsHandler({ payload }) {
  const { form } = payload;
  try {
    const res = yield call(getAlertGeofenceSettings);
    if (form) form.setFieldsValue(res);
    yield put(getAlertSettingsSuccess(res));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* editAlertSettingsHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(editAlertGeofenceSettings, payload);
    yield put(hideLoader());
    history.push('/alerts');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

export function* getAllAlertsSaga() {
  yield takeEvery(GET_ALL_ALERTS, getAllAlertsHandler);
}

export function* getAlertSettingsSaga() {
  yield takeEvery(GET_ALERT_SETTINGS, getAlertSettingsHandler);
}

export function* editAlertSettingsSaga() {
  yield takeEvery(EDIT_ALERT_SETTINGS, editAlertSettingsHandler);
}

export default function* rootSaga() {
  yield all([fork(getAllAlertsSaga), fork(getAlertSettingsSaga), fork(editAlertSettingsSaga)]);
}
