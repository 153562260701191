import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  GET_ALL_ASSETS,
  GET_ALL_MODELS,
  CREATE_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  GET_ASSET_DETAILS,
  PLAN_TRIP,
  GET_ASSET_TRIPS,
  GET_TRIP_DETAILS,
  GET_REPORTS,
  GET_REPORTS_ALL,
  GET_REPORTS_EXCEL,
  SELECT_ASSET
} from 'constants/ActionTypes';
import {
  getAllAssetsSuccess,
  getAssetDetailsSuccess,
  getAllModelsSuccess,
  getAssetTripsSuccess,
  getTripDetailsSuccess,
  getReportsSuccess,
  getReportsAllSuccess,
  getReportsExcelSuccess,
  setImportantDates
} from '../actions/Assets';
import {
  setMarkers,
  mapSetWaypoints,
  setCurrentAddress,
  showHint,
  setMarkersSuccess,
  setAssetDetailShowMarker
} from '../actions/Map';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
  getAllAssetsApi,
  getAllModelsApi,
  createAssetApi,
  editAssetApi,
  deleteAssetApi,
  getAssetDetailsApi,
  createTripPlanApi,
  getAssetTripsApi,
  getTripDetailsApi,
  getReportMovements,
  getReportLocations,
  getReportWeather,
  getReportPersonnelApi,
  getReportPersonnelAllApi,
  getReportPersonnelExcelApi
} from '../../api';
import { history } from 'appRedux/store';
import Moment from 'moment';

function* getAllAssetsHandler() {
  try {
    const res = yield call(getAllAssetsApi);
    yield put(getAllAssetsSuccess(res.assets));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getAllModelsHandler() {
  try {
    const res = yield call(getAllModelsApi);
    yield put(getAllModelsSuccess(res.models));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* createAssetHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createAssetApi, payload);
    yield put(hideLoader());
    history.push('/assets');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* editAssetHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(editAssetApi, payload);
    yield put(hideLoader());
    history.push(`/assets/details/${payload.id}`);
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* deleteAssetHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(deleteAssetApi, payload);
    yield put(hideLoader());
    history.push('/assets');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAssetDetailsHandler({ payload }) {
  const { assetId, form } = payload;
  try {
    const { map } = yield select();
    const res = yield call(getAssetDetailsApi, assetId);
    if (form)
      form.setFieldsValue({
        ...res,
        tags: res.tags ? res.tags.split(' ') : [],
        purchaseDate: Moment(res.purchaseDate)
      });

    const marker = {
      id: res.id,
      status: res.status,
      lat: res.currentLocation.latitude,
      lng: res.currentLocation.longitude,
      address: res.currentAddress
    };

    yield all([
      put(getAssetDetailsSuccess(res)),
      put(setImportantDates(res.importantDates.map(date => ({ ...date, date: Moment(date.date) })))),
      put(setMarkersSuccess([marker]))
    ]);
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* planTripHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createTripPlanApi, payload);
    yield put(hideLoader());
    history.push(`/assets/details/${payload.assetId}`);
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAssetTripsHandler({ payload }) {
  try {
    const res = yield call(getAssetTripsApi, payload);
    yield put(getAssetTripsSuccess(res.trips));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getTripDetailsHandler({ payload }) {
  try {
    const res = yield call(getTripDetailsApi, payload);
    yield put(getTripDetailsSuccess(res));
    yield put(mapSetWaypoints(res.waypoints));
    yield put(setAssetDetailShowMarker(false));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getReportsHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(getReportPersonnelApi, payload);
    const mov = yield call(getReportMovements, payload);

    yield put(
      getReportsSuccess({
        personnel: res,
        distanceTravelledInKm: mov != undefined ? mov.distanceTravelledInKm : [],

      })
    );
    yield put(hideLoader());
  } catch (err) {
    yield all([put(showMessage("EEE" + err.message)), put(hideLoader())]);
  }
}



function* getReportsAllHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(getReportPersonnelAllApi, payload);
    yield put(
      getReportsAllSuccess({
        allr: res

      })
    );
    yield put(hideLoader());
  } catch (err) {
    yield all([put(showMessage("EEE" + err.message)), put(hideLoader())]);
  }
}


function* getReportsExcelHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(getReportPersonnelExcelApi, payload);
    yield put(
      getReportsExcelSuccess()
    );
    yield put(hideLoader());
  } catch (err) {
    yield all([put(showMessage("EEE" + err.message)), put(hideLoader())]);
  }
}



function* selectAssetHandler({ payload }) {
  yield all([put(setCurrentAddress(payload)), put(showHint())]);
}

export function* getAllAssetsSaga() {
  yield takeEvery(GET_ALL_ASSETS, getAllAssetsHandler);
}

export function* getAllModelsSaga() {
  yield takeEvery(GET_ALL_MODELS, getAllModelsHandler);
}

export function* createAssetSaga() {
  yield takeEvery(CREATE_ASSET, createAssetHandler);
}

export function* editAssetSaga() {
  yield takeEvery(EDIT_ASSET, editAssetHandler);
}

export function* deleteAssetSaga() {
  yield takeEvery(DELETE_ASSET, deleteAssetHandler);
}

export function* getAssetDetailsSaga() {
  yield takeEvery(GET_ASSET_DETAILS, getAssetDetailsHandler);
}

export function* planTripSaga() {
  yield takeEvery(PLAN_TRIP, planTripHandler);
}

export function* getAssetTripsSaga() {
  yield takeEvery(GET_ASSET_TRIPS, getAssetTripsHandler);
}

export function* getTripDetailsSaga() {
  yield takeEvery(GET_TRIP_DETAILS, getTripDetailsHandler);
}

export function* getReportsSaga() {
  yield takeEvery(GET_REPORTS, getReportsHandler);
}

export function* getReportsAllSaga() {
  yield takeEvery(GET_REPORTS_ALL, getReportsAllHandler);
}

export function* getReportsExcelSaga() {
  yield takeEvery(GET_REPORTS_EXCEL, getReportsExcelHandler);
}

export function* selectAssetSaga() {
  yield takeEvery(SELECT_ASSET, selectAssetHandler);
}

export default function* rootSaga() {
  yield all([
    fork(getAllAssetsSaga),
    fork(getAllModelsSaga),
    fork(createAssetSaga),
    fork(editAssetSaga),
    fork(deleteAssetSaga),
    fork(getAssetDetailsSaga),
    fork(planTripSaga),
    fork(getAssetTripsSaga),
    fork(getTripDetailsSaga),
    fork(getReportsSaga),
    fork(selectAssetSaga),
    fork(getReportsAllSaga),
    fork(getReportsExcelSaga)
    
  ]);
}
