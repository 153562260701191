import {
    GET_DASHBOARD_USER_STATUS_SUCCESS,
    GET_DASHBOARD_ONLINE_USER_SUCCESS,
    GET_DASHBOARD_TASKS_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
    status: [],
    users: [],
    tasks: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_USER_STATUS_SUCCESS: {
            return {
                ...state,
                status: action.payload
            };
        }
        case GET_DASHBOARD_ONLINE_USER_SUCCESS: {
            return {
                ...state,
                users: action.payload
            };
        }
        case GET_DASHBOARD_TASKS_SUCCESS: {
            return {
                ...state,
                tasks: action.payload
            };
        }

        default:
            return state;
    }
};
