import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_TASKS,
  GET_TASK_DETAILS,
  CREATE_TASK,
  EDIT_TASK,
  DELETE_TASK
} from 'constants/ActionTypes';
import {
  getAllTasks,
  getAllTasksSuccess,
  getTaskDetailsSuccess,
  createTaskSuccess
} from '../actions/Tasks';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
  getAllTasksApi,
  getTaskDetailsApi,
  createTaskApi,
  editTaskApi,
  deleteTaskApi
} from '../../api';
import { history } from 'appRedux/store';

function* getAllTasksHandler() {
  try {
    const res = yield call(getAllTasksApi);
    yield put(getAllTasksSuccess(res.tasks));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getTaskDetailsHandler({ payload }) {
  const { taskId, form } = payload;
  try {
    const res = yield call(getTaskDetailsApi, taskId);
    if (form) form.setFieldsValue({ ...res });
    yield put(getTaskDetailsSuccess(res));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* createTaskHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createTaskApi, payload);
    yield all([put(createTaskSuccess(res)), put(hideLoader())]);
    //history.push('/users');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* editTaskHandler({ payload }) {
  yield put(showLoader());
  try {
    yield call(editTaskApi, payload);
    yield put(hideLoader());
    //history.push('/tasks');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* deleteTaskHandler({ payload }) {
  try {
    const res = yield call(deleteTaskApi, payload);
    yield put(getAllTasks(res));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

export function* getAllTasksSaga() {
  yield takeEvery(GET_ALL_TASKS, getAllTasksHandler);
}

export function* getTaskDetailsSaga() {
  yield takeEvery(GET_TASK_DETAILS, getTaskDetailsHandler);
}

export function* createTaskSaga() {
  yield takeEvery(CREATE_TASK, createTaskHandler);
}

export function* editTaskSaga() {
  yield takeEvery(EDIT_TASK, editTaskHandler);
}

export function* deleteTaskSaga() {
  yield takeEvery(DELETE_TASK, deleteTaskHandler);
}

export default function* rootSaga() {
  yield all([
    fork(getAllTasksSaga),
    fork(getTaskDetailsSaga),
    fork(createTaskSaga),
    fork(editTaskSaga),
    fork(deleteTaskSaga)
  ]);
}
