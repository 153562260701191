import React, { Component } from 'react';
import { Avatar, Popover } from 'antd';
import { connect } from 'react-redux';
import { userSignOut } from 'appRedux/actions/Auth';

class UserInfo extends Component {
  signOut() {
    this.props.userSignOut({ history: this.props.history });
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {/* <li>My Account</li> */}
        <li onClick={() => this.props.userSignOut()}>Sign Out</li>
      </ul>
    );

    return (
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click">
        <Avatar src="https://via.placeholder.com/150x150" className="gx-avatar gx-pointer" alt="" />
      </Popover>
    );
  }
}

export default connect(() => ({}), { userSignOut })(UserInfo);
