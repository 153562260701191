import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GET_DASHBOARD_USER_STATUS,
    GET_DASHBOARD_USER_STATUS_SUCCESS,
    GET_DASHBOARD_ONLINE_USER,
    GET_DASHBOARD_ONLINE_USER_SUCCESS,
    GET_DASHBOARD_TASKS,
    GET_DASHBOARD_TASKS_SUCCESS
} from 'constants/ActionTypes';
import {
    getDashboardUserStatus,
    getDashboardUserStatusSuccess,
    getDashboardOnlineUser,
    getDashboardOnlineUserSuccess,
    getDashboardTasks,
    getDashboardTasksSuccess
} from '../actions/Dashboard';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
    getDashboardUserStatusApi,
    getDashboardOnlineUserApi,
    getDashboardTasksApi
} from '../../api';
import { history } from 'appRedux/store';

function* getDashboardUserStatusHandler() {
    try {
        const res = yield call(getDashboardUserStatusApi);
        yield put(getDashboardUserStatusSuccess(res));
    } catch (err) {
        yield all([put(showMessage(err.message)), put(hideLoader())]);
    }
}

function* getDashboardOnlineUserHandler() {
    try {
        const res = yield call(getDashboardOnlineUserApi);
        yield put(getDashboardOnlineUserSuccess(res.users));
    } catch (err) {
        yield all([put(showMessage(err.message)), put(hideLoader())]);
    }
}

function* getDashboardTasksHandler() {
    try {
        const res = yield call(getDashboardTasksApi);
        yield put(getDashboardTasksSuccess(res.tasks));
    } catch (err) {
        yield all([put(showMessage(err.message)), put(hideLoader())]);
    }
}



export function* getDashboardUserStatusSaga() {
    yield takeEvery(GET_DASHBOARD_USER_STATUS, getDashboardUserStatusHandler);
}
export function* getDashboardOnlineUserSaga() {
    yield takeEvery(GET_DASHBOARD_ONLINE_USER, getDashboardOnlineUserHandler);
}

export function* getDashboardTasksSaga() {
    yield takeEvery(GET_DASHBOARD_TASKS, getDashboardTasksHandler);
}


export default function* rootSaga() {
    yield all([
        fork(getDashboardUserStatusSaga),
        fork(getDashboardOnlineUserSaga),
        fork(getDashboardTasksSaga)
    ]);
}
