import React, { Component } from "react";
import { Modal, Form, Input, Icon, Typography } from "antd";
import { connect } from "react-redux";
import {
  authCloseDialog,
  changePassword,
  forgotPassword,
} from "appRedux/actions/Auth";

const { Text } = Typography;
const FormItem = Form.Item;

class PasswordForm extends Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    passwordError: false,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({ ...this.state, passwordError: false });
      if (!err) {
        if (values.password === values.confirmPassword) {
          if (this.props.dialogType === "forgot") {
            this.props.forgotPassword(values.password, values.resetCode);
          } else {
            this.props.changePassword(values.password, values.oldPassword);
          }
        } else {
          this.setState({ ...this.state, passwordError: true });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Modal
        title="Change Password"
        visible={this.props.dialogVisible}
        destroyOnClose={true}
        onOk={this.handleSubmit}
        confirmLoading={this.props.dialogLoading}
        okButtonProps={{ disabled: this.props.createdTeamInfo }}
        onCancel={this.props.authCloseDialog}
      >
        <Form onSubmit={this.handleSubmit}>
          {this.props.dialogType === "change" ? (
            <FormItem {...formItemLayout} label="Old Password">
              {getFieldDecorator("oldPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please input your Old Password!",
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  autoComplete="off"
                  placeholder="Input your old Password"
                />
              )}
            </FormItem>
          ) : null}
          {this.props.dialogType === "forgot" ? (
            <FormItem {...formItemLayout} label="One Time Code">
              {getFieldDecorator("resetCode", {
                rules: [
                  { required: true, message: "Please input the code sent on email" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="number"
                  autoComplete="off"
                  placeholder="Please input the code sent on email"
                />
              )}
            </FormItem>
          ) : null}
          <FormItem {...formItemLayout} label="New Password">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your New Password!" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                autoComplete="off"
                placeholder="Input your new password"
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Confirm New Password">
            {getFieldDecorator("confirmPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input Confirm New Password!",
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                autoComplete="off"
                type="password"
                placeholder="Repeat your new password"
              />
            )}
          </FormItem>
        </Form>
        {this.state.passwordError ? (
          <div>
            <Text>Password don't match!</Text>
          </div>
        ) : null}
      </Modal>
    );
  }
}

const RegistrationForm = Form.create()(PasswordForm);

const mapStateToProps = ({ auth }) => {
  const { dialogLoading, dialogVisible, dialogType } = auth;
  return { dialogLoading, dialogVisible, dialogType };
};

export default connect(mapStateToProps, {
  authCloseDialog,
  changePassword,
  forgotPassword,
})(RegistrationForm);
