import {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_P_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_PP_DETAILS_SUCCESS,
  GET_ALL_USER_ROLES_SUCCESS,
  GET_ALL_USER_TENANTS_SUCCESS,
  CREATE_USER_SUCCESS,
  SIGNOUT_USER
} from 'constants/ActionTypes';

const INIT_STATE = {
  allUsers: [],
  currentUser: {},
  roles: [],
  tenants: [],
  createdUserInfo: null,
  detail :{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        allUsers: action.payload
      };
    }
    case GET_ALL_USERS_P_SUCCESS: {
      return {
        ...state,
        allUsers: action.payload
      };
    }
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload
      };
    }
    case GET_USER_PP_DETAILS_SUCCESS: {
      return {
        ...state,
        detail: action.payload
      };
    }
    
    case GET_ALL_USER_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload
      };
    }
    case GET_ALL_USER_TENANTS_SUCCESS: {
      return {
        ...state,
        tenants: action.payload
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        createdUserInfo: action.payload
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
