import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'

import CustomScrollbars from 'util/CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import Auxiliary from 'util/Auxiliary'
import UserProfile from './UserProfile'
import AppsNavigation from './AppsNavigation'
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from '../../constants/ThemeSetting'

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications'
    }
    return ''
  }
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup'
    }
    return ''
  }

  render() {
    const { themeType, navStyle, pathname } = this.props
    const selectedKeys = pathname.substr(1)
    const defaultOpenKeys = selectedKeys.split('/')[1]
    const { SubMenu } = Menu

    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            {/* <AppsNavigation /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline"
            >
              <Menu.Item key="dashboard">
                <Link to="/dashboard">
                  <i className="icon icon-card" />
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>

              <SubMenu
                key="users"
                title={
                  <span>
                    <i className="icon icon-user" />
                    <span>Team</span>
                  </span>
                }
              >
                <Menu.Item key="1">
                  <Link to="/users">
                    <span>All Members</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/users/new">
                    <span>Add Member</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/tasks/new">
                    <span>Add Job</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/tasks">
                    <span>All Jobs</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/users/manage">
                    <span>Manage Members</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="geofences">
                <Link to="/geofences">
                  <i className="icon icon-geo-location" />
                  <span>Geofences</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="reporys">
                <Link to="/reports">
                  <i className="icon icon-chart" />
                  <span>Reports</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="alerts">
                <Link to="/alerts">
                  <i className="icon icon-alert" />
                  <span>Alerts</span>
                </Link>
              </Menu.Item>

              {this.props.user.role === "SysAdmin" && (
              <Menu.Item key="tenants">
                <Link to="/tenants">
                  <Icon style={{ fontSize: "20px" }} type="build" />
                  <span>Tenants Management</span>
                </Link>
              </Menu.Item>
              )}

            </Menu>

          </CustomScrollbars>
        </div>
      </Auxiliary>
    )
  }
}

SidebarContent.propTypes = {}
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings
  const { user } = auth;
  return { navStyle, themeType, locale, pathname,user }
}
export default connect(mapStateToProps)(SidebarContent)
