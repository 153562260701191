import {
  GET_ALL_GEOFENCES,
  GET_ALL_GEOFENCES_SUCCESS,
  SELECT_GEOFENCE,
  CREATE_GEOFENCE,
  EDIT_GEOFENCE,
  DELETE_GEOFENCE,
  GET_GEOFENCE_DETAILS,
  GET_GEOFENCE_DETAILS_SUCCESS,
  SET_POINTS
} from 'constants/ActionTypes';

export const getAllGeofences = () => {
  return {
    type: GET_ALL_GEOFENCES
  };
};

export const getAllGeofencesSuccess = geofences => {
  return {
    type: GET_ALL_GEOFENCES_SUCCESS,
    payload: geofences
  };
};

export const getGeofenceDetails = (geofenceId, form) => {
  return {
    type: GET_GEOFENCE_DETAILS,
    payload: { geofenceId, form }
  };
};

export const getGeofenceDetailsSuccess = geofence => {
  return {
    type: GET_GEOFENCE_DETAILS_SUCCESS,
    payload: geofence
  };
};

export const selectGeofence = geofence => {
  return {
    type: SELECT_GEOFENCE,
    payload: geofence
  };
};

export const createGeofence = geofence => {
  return {
    type: CREATE_GEOFENCE,
    payload: geofence
  };
};

export const editGeofence = geofence => {
  return {
    type: EDIT_GEOFENCE,
    payload: geofence
  };
};

export const deleteGeofence = geofenceId => {
  return {
    type: DELETE_GEOFENCE,
    payload: geofenceId
  };
};

export const setPoints = points => {
  return {
    type: SET_POINTS,
    payload: points
  };
};
