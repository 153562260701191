import { store } from "NextApp";
import { personnelBase, apiBuilder, getAuthHeaders } from "./index";

export const getDashboardUserStatusApi = () =>
  apiBuilder(`${personnelBase}/dashboard/userstates`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getDashboardOnlineUserApi = () =>
  apiBuilder(`${personnelBase}/dashboard/onlineusers`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getDashboardBreakUsersApi = () =>
  apiBuilder(`${personnelBase}/dashboard/breakusers`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getDashboardTasksApi = () =>
  apiBuilder(`${personnelBase}/dashboard/tasks`, {
    method: "GET",
    headers: getAuthHeaders(),
  });
