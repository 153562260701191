export const INIT = 'INIT';
export const INIT_PATH = 'INIT_PATH';

// Customizer
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

// Auth
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_REFRESH = 'SIGNIN_USER_REFRESH';
export const SIGNIN_USER_REFRESH_SUCCESS = 'SIGNIN_USER_REFRESH_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const AUTH_CANCEL_LOADING = 'AUTH_CANCEL_LOADING';
export const AUTH_CLOSE_DIALOG = 'AUTH_CLOSE_DIALOG';
export const AUTH_OPEN_DIALOG = 'AUTH_OPEN_DIALOG';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

// Assets
export const GET_ALL_ASSETS = 'GET_ALL_ASSETS';
export const GET_ALL_ASSETS_SUCCESS = 'GET_ALL_ASSETS_SUCCESS';
export const FILTER_ASSETS = 'FILTER_ASSETS';
export const GET_ALL_MODELS = 'GET_ALL_MODELS';
export const GET_ALL_MODELS_SUCCESS = 'GET_ALL_MODELS_SUCCESS';
export const CREATE_ASSET = 'CREATE_ASSET';
export const EDIT_ASSET = 'EDIT_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const GET_ASSET_DETAILS = 'GET_ASSET_DETAILS';
export const GET_ASSET_DETAILS_SUCCESS = 'GET_ASSET_DETAILS_SUCCESS';
export const SET_IMPORTANT_DATES = 'SET_IMPORTANT_DATES';
export const PLAN_TRIP = 'PLAN_TRIP';
export const SELECT_ASSET = 'SELECT_ASSET';
export const GET_ASSET_TRIPS = 'GET_ASSET_TRIPS';
export const GET_ASSET_TRIPS_SUCCESS = 'GET_ASSET_TRIPS_SUCCESS';
export const GET_TRIP_DETAILS = 'GET_TRIP_DETAILS';
export const GET_TRIP_DETAILS_SUCCESS = 'GET_TRIP_DETAILS_SUCCESS';
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ALL = 'GET_REPORTS_ALL';
export const GET_REPORTS_ALL_SUCCESS = 'GET_REPORTS_ALL_SUCCESS';

export const GET_REPORTS_EXCEL = 'GET_REPORTS_EXCEL';
export const GET_REPORTS_EXCEL_SUCCESS = 'GET_REPORTS_EXCEL_SUCCESS';

// Geofences
export const GET_ALL_GEOFENCES = 'GET_ALL_GEOFENCES';
export const GET_ALL_GEOFENCES_SUCCESS = 'GET_ALL_GEOFENCES_SUCCESS';
export const GET_GEOFENCE_DETAILS = 'GET_GEOFENCE_DETAILS';
export const GET_GEOFENCE_DETAILS_SUCCESS = 'GET_GEOFENCE_DETAILS_SUCCESS';
export const CREATE_GEOFENCE = 'CREATE_GEOFENCE';
export const EDIT_GEOFENCE = 'EDIT_GEOFENCE';
export const DELETE_GEOFENCE = 'DELETE_GEOFENCE';
export const SELECT_GEOFENCE = 'SELECT_GEOFENCE';
export const SET_POINTS = 'SET_POINTS';

// Alerts
export const GET_ALL_ALERTS = 'GET_ALL_ALERTS';
export const GET_ALL_ALERTS_SUCCESS = 'GET_ALL_ALERTS_SUCCESS';
export const FILTER_ALERTS = 'FILTER_ALERTS';
export const GET_ALERT_SETTINGS = 'GET_ALERT_SETTINGS';
export const GET_ALERT_SETTINGS_SUCCESS = 'GET_ALERT_SETTINGS_SUCCESS';
export const EDIT_ALERT_SETTINGS = 'EDIT_ALERT_SETTINGS';

// Devices

// Reports

// Users
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_P = 'GET_ALL_USERS_P';
export const GET_ALL_USERS_P_SUCCESS = 'GET_ALL_USERS_P_SUCCESS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_PP_DETAILS = 'GET_USER_PP_DETAILS';
export const GET_USER_PP_DETAILS_SUCCESS = 'GET_USER_PP_DETAILS_SUCCESS';
export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES';
export const GET_ALL_USER_ROLES_SUCCESS = 'GET_ALL_USER_ROLES_SUCCESS';
export const GET_ALL_USER_TENANTS = 'GET_ALL_USER_TENANTS';
export const GET_ALL_USER_TENANTS_SUCCESS = 'GET_ALL_USER_TENANTS_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';


//DASHBOARD
export const GET_DASHBOARD_USER_STATUS = 'GET_DASHBOARD_USER_STATUS';
export const GET_DASHBOARD_USER_STATUS_SUCCESS = 'GET_DASHBOARD_USER_STATUS_SUCCESS';
export const GET_DASHBOARD_ONLINE_USER = 'GET_DASHBOARD_ONLINE_USER';
export const GET_DASHBOARD_ONLINE_USER_SUCCESS = 'GET_DASHBOARD_ONLINE_USER_SUCCESS';
export const GET_DASHBOARD_TASKS = 'GET_DASHBOARD_TASKS';
export const GET_DASHBOARD_TASKS_SUCCESS = 'GET_DASHBOARD_TASKS_SUCCESS';


// Tasks
export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS';
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const EDIT_TASK = 'EDIT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';

// Sensors
export const GET_ALL_SENSORS = 'GET_ALL_SENSORS';
export const GET_ALL_SENSORS_SUCCESS = 'GET_ALL_SENSORS_SUCCESS';
export const GET_SENSOR_DETAILS = 'GET_SENSOR_DETAILS';
export const GET_SENSOR_DETAILS_SUCCESS = 'GET_SENSOR_DETAILS_SUCCESS';
export const GET_ALL_SENSOR_MODELS = 'GET_ALL_SENSOR_MODELS';
export const GET_ALL_SENSOR_MODELS_SUCCESS = 'GET_ALL_SENSOR_MODELS_SUCCESS';
export const CREATE_SENSOR = 'CREATE_SENSOR';
export const EDIT_SENSOR = 'EDIT_SENSOR';
export const DELETE_SENSOR = 'DELETE_SENSOR';

// Map
export const SET_MARKERS = 'SET_MARKERS';
export const SET_MARKERS_SUCCESS = 'SET_MARKERS_SUCCESS';
export const UPDATE_MARKER = 'UPDATE_MARKER';
export const SET_MAP_API = 'SET_MAP_API';
export const MAP_FIT_BOUNDS = 'MAP_FIT_BOUNDS';
export const MAP_SET_WAYPOINTS = 'MAP_SET_WAYPOINTS';
export const MAP_SET_DIRECTION_RESULT = 'MAP_SET_DIRECTION_RESULT';
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS';
export const SET_CURRENT_ADDRESS_SUCCESS = 'SET_CURRENT_ADDRESS_SUCCESS';
export const SHOW_HINT = 'SHOW_HINT';
export const HIDE_HINT = 'HIDE_HINT';
export const SET_ASSET_DETAIL_SHOW_MARKER = 'SET_ASSET_DETAIL_SHOW_MARKER';
