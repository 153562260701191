import {
  alertsBase,
  apiBuilder,
  getAuthHeaders,
  GetNewAuthHeaders,
} from "./index";
import axios from "axios";

const instance = axios.create();

export const getAllAlertsApi = () =>
  apiBuilder(`${alertsBase}/alert`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getAlertGeofenceSettings = () =>
  apiBuilder(`${alertsBase}/rule/geofence`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const editAlertGeofenceSettings = (settings) =>
  apiBuilder(`${alertsBase}/rule/geofence`, {
    method: "PUT",
    headers: getAuthHeaders(),
    body: JSON.stringify(settings),
  });

export const getAllAlertsTriggersApi = () =>
  apiBuilder(`${alertsBase}/alert/AlertTrigger?triggerSource=2`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getAlertTriggersApi = (alertId) =>
  apiBuilder(`${alertsBase}/alert/${alertId}/AlertTrigger`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const createAlertApi = async (alert) => {
  let resource = `${alertsBase}/alert/AlertTrigger`;
  let res = await instance.post(
    resource,
    JSON.stringify(alert),
    GetNewAuthHeaders()
  );
  if (res) return { success: true, message: "Alert Created Successfully!" };
};

export const editAlertApi = async (alertId, alert) => {
  let resource = `${alertsBase}/alert/${alertId}/AlertTrigger`;
  let res = await instance.put(
    resource,
    JSON.stringify(alert),
    GetNewAuthHeaders()
  );
  if (res) return { success: true, message: "Alert Updated Successfully!" };
};

export const deleteAlertApi = (alertId) =>
  apiBuilder(`${alertsBase}/alert/${alertId}/AlertTrigger`, {
    method: "DELETE",
    headers: getAuthHeaders(),
  });

export const sendSimpleAlertApi = (alert) =>
  apiBuilder(`${alertsBase}/alert/Simple`, {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify(alert),
  });
